import { SelectableUrgentHealthIssue, UrgentDiet } from "@/api/api.dto";

export type SelectableUrgentHealthIssueView = {
  id: number;
  displayName: string;
  dispensing_guidance?: string;
};

export type UrgentDietView = {
  urgentHealthIssue: SelectableUrgentHealthIssueView;
  endDate: string;
};

export function urgentDietPayloadToViewModel(urgentDiet: UrgentDiet): UrgentDietView {
  return {
    urgentHealthIssue: {
      id: urgentDiet.urgent_health_issue.id,
      displayName: urgentDiet.urgent_health_issue.display_name
    },
    endDate: urgentDiet.end_date
  } as UrgentDietView;
}

export function selectableUrgentHealthIssuesToViewModel(
  urgentHealthIssuesPayload: SelectableUrgentHealthIssue[]
): SelectableUrgentHealthIssueView[] {
  return urgentHealthIssuesPayload.map((issue: SelectableUrgentHealthIssue) => ({
    id: issue.id,
    displayName: issue.display_name,
    dispensing_guidance: issue.dispensing_guidance
  }));
}
