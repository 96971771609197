
import Vue from "vue";
import { ApiInterface } from "@/api/api.interface";

import { mapPetDetailsResponseToViewModel, PetDetails } from "@/components/common.vm";
import { GetPetByIdResponse, UrgentDietResponse } from "@/api/api.dto";
import {
  selectableUrgentHealthIssuesToViewModel,
  SelectableUrgentHealthIssueView,
  urgentDietPayloadToViewModel,
  UrgentDietView
} from "@/components/UrgentDiet/UrgentDiet.vm";
import { ConsultationModes } from "@/components/Dashboard/Dashboard.vm";
import { RouteNames } from "@/router/routeNames";
import store from "@/store";
import WithOldVetBar from "@/layouts/WithOldVetBar.vue";
import { FeatureFlags } from "@/api/featureFlags/featureFlags.api";

type Data = {
  error: string;
  hasUrgentDiet: boolean;
  petDetails: PetDetails;
  petId: number;
  selectableUrgentHealthIssues?: SelectableUrgentHealthIssueView[];
  selectedUrgentHealthIssueId: number;
  urgentDiet?: UrgentDietView;
  numberOfDays?: number;
  isViewOrEditActiveMode: boolean;
  submitDisabled: boolean;
};

type Inject = {
  Api: ApiInterface;
};

type Methods = {
  loadPetDetails(): Promise<void>;
  loadUrgentDiet(): Promise<void>;
  onClickContinue(event: any): Promise<void>;
  reset(): void;
  selectUrgentDiet(): Promise<void>;
  navigateToDietDetails(): void;
};

type Computed = {
  shouldShowDietTable(): boolean;
};

export default Vue.extend<Data, Methods, {}, any & Inject>({
  name: "UrgentDiet",
  components: { WithOldVetBar },
  inject: ["Api"],
  data() {
    return {
      error: "",
      hasUrgentDiet: false,
      petDetails: {} as PetDetails,
      selectedUrgentHealthIssueId: 0,
      petId: 0,
      selectableUrgentHealthIssues: [],
      numberOfDays: undefined,
      isViewOrEditActiveMode:
        store.state.consultationMode === ConsultationModes.VIEW_OR_EDIT_ACTIVE,
      submitDisabled: false
    };
  },
  async mounted() {
    this.petId = +this.$route.params.pet_id;
    await this.loadPetDetails();
    await this.loadUrgentDiet();
  },
  methods: {
    async loadPetDetails(): Promise<void> {
      const response: GetPetByIdResponse = await this.Api.getPetById(this.petId);
      if (response.success) {
        this.petDetails = mapPetDetailsResponseToViewModel(response.petDetails);
      } else {
        // handle error
      }
    },
    async loadUrgentDiet(): Promise<void> {
      const response: UrgentDietResponse = await this.Api.getUrgentDiet(this.petId);
      if (response.success && response.resp) {
        this.hasUrgentDiet = response.resp.has_active_urgent_diet;
        if (response.resp.has_active_urgent_diet && response.resp.active_urgent_diet) {
          this.urgentDiet = urgentDietPayloadToViewModel(response.resp.active_urgent_diet);
        } else {
          this.selectableUrgentHealthIssues = selectableUrgentHealthIssuesToViewModel(
            response.resp.selectable_urgent_health_issues || []
          );
        }
      } else {
        // handle errors
      }
    },
    async selectUrgentDiet(): Promise<void> {
      this.submitDisabled = true;
      const response = await this.Api.createUrgentDiet(
        this.petId,
        this.selectedUrgentHealthIssueId,
        Number(this.numberOfDays)
      );
      if (response.success) {
        const isFeedingPreferencesEnabled = await FeatureFlags.isEnabled("v2p:feeding-preferences");
        const isBrandQuestionEnabled = await FeatureFlags.isEnabled("v2p:brand-question");

        const routeName =
          isFeedingPreferencesEnabled || isBrandQuestionEnabled
            ? RouteNames.FEEDING_PREFERENCES
            : RouteNames.DIET_REQUIREMENTS;

        await this.$router.push({
          name: routeName,
          params: { pet_id: this.petId.toString() }
        });
      } else {
        this.error = response.errorMessage;
        this.submitDisabled = false;
      }
    },
    reset() {
      this.error = "";
      this.selectedUrgentHealthIssueId = 0;
    },
    async onClickContinue(event: any): Promise<void> {
      event.preventDefault();
      this.submitDisabled = true;
      this.error = "";
      if (this.hasUrgentDiet) {
        const [isFeedingPreferencesEnabled, isBrandQuestionEnabled] = await Promise.all([
          FeatureFlags.isEnabled("v2p:feeding-preferences"),
          FeatureFlags.isEnabled("v2p:brand-question")
        ]);

        const routeName =
          isFeedingPreferencesEnabled || isBrandQuestionEnabled
            ? RouteNames.FEEDING_PREFERENCES
            : RouteNames.DIET_REQUIREMENTS;

        await this.$router.push({
          name: routeName,
          params: { pet_id: this.petId.toString() }
        });
      }
      if (this.selectedUrgentHealthIssueId) {
        await this.selectUrgentDiet();
      } else {
        this.error =
          'Please, select type of urgent diet or answer "NO" to the urgent requirements question.';
        this.submitDisabled = false;
      }
    },
    navigateToDietDetails(): void {
      this.submitDisabled = true;
      this.$router.push({ name: "diet-requirements", params: { pet_id: this.petId.toString() } });
      this.submitDisabled = false;
    }
  },
  computed: {
    showDispensingTable() {
      // This is an intermediate solution, the final solution will be displaying the table
      // created in HTML returned by dispensing_guidance
      const urgentHealthIssuesWithTable = [1, 3];
      const requiresTable =
        urgentHealthIssuesWithTable.indexOf(this.selectedUrgentHealthIssueId) > -1;
      return this.selectedUrgentHealthIssueId && requiresTable;
    },
    dispensingGuide() {
      return this.selectableUrgentHealthIssues
        ? this.selectableUrgentHealthIssues[this.selectedUrgentHealthIssueId - 1]
            .dispensing_guidance
        : "";
    }
  }
});
